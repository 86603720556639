import localFont from 'next/font/local';

import { colors } from 'styles/variables.module';

export const antDesignComponentsProviderConfigs = {
  token: {
    colorPrimary: colors.primary_6
  },
  Button: {
    borderRadius: 12
  },
  Dropdown: {
    borderRadius: 12
  },
  Card: {
    borderRadius: 12
  },
  Menu: {
    itemHoverBg: colors.primary_1
  },
  Progress: {
    lineBorderRadius: 40,
    marginXS: 0,
    marginXSS: 0,
    paddingXS: 4
  },
  Input: {
    borderRadius: 12,
    colorBgContainer: colors.gray_4
  },
  Popover: {
    borderRadius: 12
  },
  Collapse: {
    headerPadding: 20,
    contentPadding: 20
  },
  Drawer: {
    borderRadius: 12
  }
};

export const localInterFont = localFont({
  src: [
    {
      path: '../../../public/fonts/Inter/Inter-Bold.woff2',
      weight: 'bold',
      style: 'normal'
    },
    {
      path: '../../../public/fonts/Inter/Inter-Medium.woff2',
      weight: '600',
      style: 'normal'
    },
    {
      path: '../../../public/fonts/Inter/Inter-Regular.woff2',
      weight: 'normal',
      style: 'normal'
    }
  ],
  preload: true,
  adjustFontFallback: 'Arial',
  display: 'swap'
});
