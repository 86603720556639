import { IAuthState, IAuthAction } from 'providers/authProvider/types';
import {
  LOG_OUT_USER,
  SET_USER
} from 'providers/authProvider/context/actionTypes';

export const authReducer = (
  state: IAuthState,
  action: IAuthAction
): IAuthState => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    case LOG_OUT_USER:
      return { ...state, user: null };
    default:
      return state;
  }
};
