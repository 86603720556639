import styled from 'styled-components';

import { HEIGHT, sizes, media } from 'styles/variables.module';

export const MainLayoutStyleWrapper = styled.div`
  display: grid;
  gap: ${sizes.sp6xl};
`;

export const MainStyleWrapper = styled.div`
  margin-top: ${HEIGHT.WEB.HEADER};
  overflow: hidden;
  min-height: 50vh;
  &[data-main='true'] {
    padding: 0 ${sizes.spm};
  }

  &[data-main='false'] {
    padding: ${sizes.sps} ${sizes.spXl} 0 ${sizes.spXl};
  }

  &[data-checkout-page='true'] {
    min-height: 80vh;
  }

  ${media.sm(` 
    position: relative;
    margin-top: ${HEIGHT.MOBILE.HEADER};
    padding: 0 ${sizes.spm} ${sizes.spm};
  `)}
`;
