'use client';

import { FC, useReducer } from 'react';

import { authReducer } from './context/reducer';
import { AuthContext } from './context';
import { IAuthProviderProps } from './types';

const AuthProvider: FC<IAuthProviderProps> = ({ children, user }) => {
  const [state, dispatch] = useReducer(authReducer, { user });

  const contextValue = { state, dispatch };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
