'use client';

import { FC, useState } from 'react';
import Grid from 'antd/lib/grid';

import Icon from 'components/atoms/Icon';
import DropDown from 'components/atoms/DropDown';
import UserAvatar from 'components/atoms/UserAvatar';
import BodyText from 'components/atoms/BodyText';
import { EnvConfig } from 'configs/env';
import useTranslations from 'hooks/common/useTranslations';
import {
  ArrowIconWrapper,
  UserAvatarContainerWrapper,
  DividerStyle,
  MenuItemStylesLoggedUser,
  MenuStylesLoggedUser,
  UserAvatarStyle,
  UserNameAndAvatarWrapper
} from './styles';
import { colors } from 'styles/variables.module';
import { ILoggedUserInfoProps } from './types';

const { useBreakpoint } = Grid;

const LoggedUserInfo: FC<ILoggedUserInfoProps> = ({
  userName,
  avatarUrl,
  onLogout,
  navigateToDashboardOrAccounts
}) => {
  const { t } = useTranslations('common');
  const { xs } = useBreakpoint();
  const [dropDownVisible, setDropDownVisible] = useState<Boolean>(false);
  const iconName = dropDownVisible ? 'arrowUp' : 'arrowDown';

  return (
    <DropDown
      onOpenChange={setDropDownVisible}
      dropdownRender={() => (
        <MenuStylesLoggedUser>
          <UserNameAndAvatarWrapper>
            <UserAvatarStyle size={48} src={avatarUrl} userName={userName} />
            <BodyText size="small" fontWeight="bold" text={userName} ellipsis />
          </UserNameAndAvatarWrapper>
          <MenuItemStylesLoggedUser
            onClick={() => navigateToDashboardOrAccounts('dashboard', '/')}
          >
            <BodyText
              size="small"
              fontWeight="regular"
              text={t('myProjects')}
            />
          </MenuItemStylesLoggedUser>
          <MenuItemStylesLoggedUser
            onClick={() =>
              navigateToDashboardOrAccounts(
                'blank',
                `${EnvConfig.myBrandBooks}/ai-logo-generator/app/my-brand-book`
              )
            }
          >
            <BodyText
              size="small"
              fontWeight="regular"
              text={t('myBrandBooks')}
            />
          </MenuItemStylesLoggedUser>
          <MenuItemStylesLoggedUser
            onClick={() => navigateToDashboardOrAccounts('accounts', '/')}
          >
            <BodyText
              size="small"
              fontWeight="regular"
              text={t('accountSettings')}
            />
          </MenuItemStylesLoggedUser>
          <DividerStyle />
          <MenuItemStylesLoggedUser onClick={onLogout}>
            <BodyText
              size="small"
              fontWeight="regular"
              text={t('logOut')}
              color={colors.red_6}
            />
          </MenuItemStylesLoggedUser>
        </MenuStylesLoggedUser>
      )}
      getPopupContainer={event => event.parentNode as HTMLElement}
      trigger={['click']}
    >
      <UserAvatarContainerWrapper>
        <UserAvatar size={xs ? 28 : 40} userName={userName} src={avatarUrl} />
        <ArrowIconWrapper>
          <Icon name={iconName} width={12} height={12} />
        </ArrowIconWrapper>
      </UserAvatarContainerWrapper>
    </DropDown>
  );
};

export default LoggedUserInfo;
